import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://apibuxida-jgwob.run-eu-central1.goorm.io/',
  // baseURL: 'https://api2.buxida.com/',	
   baseURL: 'https://api.buxida.com/',
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' },
  headers: {
	  'Access-Control-Allow-Origin': '*',
	  'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
	  'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
	},
})

Vue.prototype.$http = axiosIns

export default axiosIns
