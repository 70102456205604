<template>
  <b-card
    v-if="userData"
    class="card-congratulation-medal"
  >
	<h4>Compte BUXIDA actuel : <b-badge>{{ userData.C_RS }}</b-badge></h4>
	<b-form-group
		label="Liste des sites actifs"
		label-for="soc"		
	 >
		<b-form-select
		id="soc"
		v-model="userData.C_UISOC"
		:options="resultSoc"
		/>
	</b-form-group>
    <b-button
		v-ripple.400="'rgba(255, 255, 255, 0.15)'"
		variant="secondary"
		@click="majsoc()"
    >
      Changer de compte
    </b-button>
	<hr>
		<b-row>
			<b-col>
				<b-form-group
					label="Libelle"
					label-for="Libellecpt"
				>
				  <b-form-input
					v-model="libcpt"
					id="Libellecpt"
				  />
				</b-form-group>
			</b-col>
			<b-col>
				<b-button
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					variant="primary"
					@click="addctp()"
				>
				  Ajouter un compte
				</b-button>
			</b-col>
		</b-row>

  </b-card>
</template>

<script>
import {
  BCard, BButton, BFormGroup, BFormSelect, BBadge, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
	components: {
		BCard,
		BButton,
		BFormGroup,
		BFormSelect,
		BBadge,
		BFormInput,
		BRow, 
		BCol,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			userData: {},
			user: {},
			libcpt: '',
		}
	},
	props: {
		resultSoc: {
			type: Object,
			default: () => [],
		},
	},
	methods: {
		majsoc() {
			this.$http.post('/admin/majsoc', { CUI: this.userData.C_UISOC })
			.then(response => {
				if (response.data.res === 1) {
					this.userData.C_UISOC = response.data.C_UISOC
					this.userData.C_RS = response.data.C_RS
					localStorage.setItem('userData', JSON.stringify(this.userData))
					this.$toast({
					component: ToastificationContent,
					position: 'top-right',
						props: {
						  title: 'Ok c\'est fait ! 🎉',
						  icon: 'CheckIcon',
						  variant: 'success',
						  text: 'Tu peut te connecter sur BUXIDA !',
						},
					},
					{
					timeout: 8000,
					position: 'top-center',
					})
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'danger',
							text: 'Erreur : activation impossible',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})
				}
			})	
		},
		addctp() {
			if (this.libcpt !== '') {
				this.$http.post('/admin/addcpt', { CLIBELLE: this.libcpt })
				.then(response => {
					if (response.data.res === 1) {
						this.userData.C_UISOC = response.data.C_UISOC
						this.userData.C_RS = response.data.C_RS
						localStorage.setItem('userData', JSON.stringify(this.userData))
						this.$toast({
						component: ToastificationContent,
						position: 'top-right',
							props: {
							  title: 'Ok c\'est fait ! 🎉',
							  icon: 'CheckIcon',
							  variant: 'success',
							  text: 'Compte créé !',
							},
						},
						{
						timeout: 8000,
						position: 'top-center',
						})
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Oups',
								icon: 'SmileIcon',
								variant: 'danger',
								text: 'Erreur : activation impossible',
							},
						},
						{
							timeout: 4000,
							position: 'top-center',
						})
					}
				})	
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'danger',
						text: 'Erreur : Merci de saisir un libelle',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})
			}
		},
  	},
	created() {
		this.userData = JSON.parse(localStorage.getItem('userData'))
	},	
}
</script>
